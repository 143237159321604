import React, {useCallback, useMemo} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';

import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {IColorConfiguration} from '@chancer/common/lib/utils/ColorUtils';
import {Avatar} from '../Avatar/Avatar';
import {
  COLOR_GREY_6,
  COLOR_LIGHT,
  FONT_COPY,
  FONT_SUB_TITLE,
} from '../Styles/DesignSystem-chancer';
import {CompositedViewStyle} from '../Styles/StyleTypes';
import {
  CompStatus,
  TLiveScoreSummary,
} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';

export interface ICompDetailsListItemProps {
  comp: ICompSummaryAndEntry;
  colorConfig: IColorConfiguration;
  noColorBackground?: boolean;
  promoMode?: boolean;
  children?: React.ReactNode;
  onPress?: (details: ICompSummaryAndEntry) => void;
}

export const ITEM_HEIGHT = 64;

export const CompDetailsListItem: React.FC<ICompDetailsListItemProps> = (
  props,
) => {
  const {comp, colorConfig, onPress} = props;
  const isUpcoming = comp.summary.status === CompStatus.DRAFT;

  const contextualContainer: CompositedViewStyle = useMemo(
    () => [
      styles.container,
      props.noColorBackground !== true && {
        backgroundColor: colorConfig.leaderboardItemsBackground,
      },
      isUpcoming && {opacity: 0.5},
    ],
    [
      colorConfig.leaderboardItemsBackground,
      props.noColorBackground,
      isUpcoming,
    ],
  );

  const _onPress = useCallback(() => {
    if (onPress) {
      onPress(comp);
    }
  }, [comp, onPress]);

  return (
    <Pressable
      style={contextualContainer}
      disabled={isUpcoming || !onPress}
      onPress={_onPress}>
      <>
        <Avatar
          imageUrl={comp.summary.promo.image?.url}
          highlightWidth={0}
          highlightGap={0}
          size={48}
          radius={8}
        />
        <View style={styles.copyContainer}>
          {props.promoMode ? (
            <Text style={styles.name} numberOfLines={1} ellipsizeMode="tail">
              {comp.summary.shortName}
            </Text>
          ) : showLiveScore(props.comp.summary.liveScore) ? (
            <View>
              <LiveScore
                key="home"
                name={comp.summary.liveScore?.homeName ?? ''}
                score={comp.summary.liveScore?.homeScore ?? 0}
              />
              <LiveScore
                key="away"
                name={comp.summary.liveScore?.awayName ?? ''}
                score={comp.summary.liveScore?.awayScore ?? 0}
              />
            </View>
          ) : (
            <Text style={styles.name} numberOfLines={1} ellipsizeMode="tail">
              {comp.summary.shortName} {comp.summary.roundName}
            </Text>
          )}
          <Text style={styles.time} numberOfLines={1} ellipsizeMode="tail">
            {!props.promoMode
              ? `${formatDate(comp.summary.starts.toDate())} ∙ ${
                  comp.summary.entriesCount
                } fans`
              : `${comp.summary.name}`}
          </Text>
        </View>
        {props.children}
      </>
    </Pressable>
  );
};

const showLiveScore = (liveScore?: TLiveScoreSummary) =>
  liveScore !== undefined &&
  liveScore.homeName &&
  liveScore.awayName &&
  liveScore.homeScore !== undefined &&
  liveScore.awayScore !== undefined;

// Today, Yesterday or Apr 1
const formatDate = (date: Date) => {
  const now = new Date();
  if (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate()
  ) {
    return 'Today';
  }

  now.setDate(now.getDate() - 1);
  if (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate()
  ) {
    return 'Yesterday';
  }

  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });
};

const styles = StyleSheet.create({
  container: {
    userSelect: 'none',
    position: 'relative',
    height: ITEM_HEIGHT,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    marginTop: 2,
    overflow: 'hidden',
    width: '100%',
  },
  copyContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'column',
    marginLeft: 12,
    overflow: 'hidden',
  },
  name: {
    color: COLOR_LIGHT,
    fontFamily: FONT_SUB_TITLE,
    fontSize: 16,
  },
  time: {
    color: COLOR_GREY_6,
    fontFamily: FONT_COPY,
    fontSize: 13,
  },
  liveScoreContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const LiveScore: React.FC<{name: string; score: number}> = (props) => {
  return (
    <View style={styles.liveScoreContainer}>
      <Text style={styles.name}>{props.name}</Text>
      <Text style={styles.name}>{props.score}</Text>
    </View>
  );
};

import React, {ReactElement, useEffect} from 'react';
import {ImageBackground, Pressable, StyleSheet, Text, View} from 'react-native';

import {IRemoteEnterCompOverlayConfig} from '@chancer/common/lib/core/config/RemoteConfig';
import {ICompSummaryAndEntry} from '@chancer/common/lib/core/state/model/CompetitionModel';
import {
  AppPlatform,
  EnterCompContentType,
  EnterCompStatus,
} from '@chancer/common/lib/interfaces/client/ClientInterfaces';
import {
  TFirebaseChallengeGroup,
  TFirebaseQuestion,
  TFirebaseUser,
} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {QuestionStatus} from '@chancer/common/lib/interfaces/firestore/FirestoreInterfaces';
import {
  getChallengedId,
  getChallengerId,
} from '@chancer/common/lib/utils/ChallengeUtils';
import {getColorConfiguration} from '@chancer/common/lib/utils/ColorUtils';
import {getUserByIdStream} from '@chancer/common/lib/utils/UserHttpUtils';
import {Subscription} from 'rxjs';
import {Avatar} from '../../Avatar/Avatar';
import {PrimaryButton} from '../../Button/PrimaryButton';
import {SecondaryButton} from '../../Button/SecondaryButton';
import {ChallengeVsAvatars} from '../../Challenges/ChallengeVsAvatars';
import {CompDetailsListItem} from '../../CompDetailsList/CompDetailsListItem';
import {ImageWithFallback} from '../../Core/ImageWithFallback';
import {useSetOnce} from '../../Hooks/CommonHooks';
import {
  COLOR_LIGHT,
  COLOR_PURPLE,
  FONT_COPY,
  FONT_COPY_BOLD,
  FONT_SUB_TITLE,
} from '../../Styles/DesignSystem-chancer';
import {FormattedDescription} from './FormattedDescription';

enum PromoType {
  NONE = 'NONE',
  PLAY_ANOTHER_GAME = 'PLAY_ANOTHER_GAME',
  NEW_CHALLENGE = 'NEW_CHALLENGE',
}

interface IProps {
  type: EnterCompContentType;
  remoteConfig: IRemoteEnterCompOverlayConfig;
  appPlatform: AppPlatform;
  title?: string;
  description?: string;
  backgroundColor?: string;
  questions: TFirebaseQuestion[] | null;
  compEntryStatus: EnterCompStatus;
  challenge: TFirebaseChallengeGroup | null;
  upcomingComps: ICompSummaryAndEntry[];
  mediaComponent?: ReactElement;
  currentUser: TFirebaseUser | null;
  gameUrl: string;
  cdnUrl: string;
  vendorImageUrl?: string;
  compsPlayed: number;
  isEntryWithMissedQuestions: boolean;
  vendorName?: string;
  showPromo?: boolean; // For testing purposes
  onScreenAnalytics: (
    type: string,
    promo: string,
    isTempEntry: boolean,
    missedQuestions: boolean,
  ) => void;
  onRetryEntry: () => void;
  onCompetitionSelected: (comp: ICompSummaryAndEntry) => void;
  onChallengeSelected: ((challenge: TFirebaseChallengeGroup) => void) | null;
  onNewChallengeSelected: (() => void) | null;
}

// enum EnterCompVariant {
//   ERROR = 'error',
//   CHALLENGE_ACCEPTED = 'challenge-accepted',
//   PROMO_NEW_CHALLENGE = 'new-challenge',
//   PROMO_PLAY_ANOTHER_GAME = 'play-another-game',
//   FIRST_N_GAMES = 'first-n-games',
//   FIRST_N_VENDOR_GAMES = 'first-n-vendor-games',
//   TEMP_ACCOUNT_DEFAULT = 'temp-account-default',
//   TEMP_ACCOUNT_FIRST_N_GAMES = 'temp-account-first-n-games',
//   TEMP_ACCOUNT_FIRST_N_VENDOR_GAMES = 'temp-account-first-n-vendor-games',
// }

export const EnterCompContent: React.FC<IProps> = (props) => {
  const {
    type,
    remoteConfig,
    appPlatform,
    compEntryStatus,
    challenge,
    currentUser,
    gameUrl,
    compsPlayed,
    isEntryWithMissedQuestions,
    onScreenAnalytics,
  } = props;
  const [showError, setShowError] = React.useState(false);
  const [promoType, setPromoType] = React.useState<PromoType>();

  const [challengerId, setChallengerId] = React.useState<string | undefined>(
    undefined,
  );
  const [challenger, setChallenger] = React.useState<TFirebaseUser | null>(
    null,
  );

  // TODO: Re-write so we have ove set of logic to determine what to display, then render the correct component
  // there's too many contitions all through the code in this component

  const isTemporary =
    appPlatform === AppPlatform.WEB && currentUser?.temporary === true;
  const canShowNewChallengePromo =
    props.onNewChallengeSelected !== null &&
    !isEntryWithMissedQuestions &&
    !props.challenge;

  const canShowPlayAnotherGamePromo = useSetOnce(
    props.upcomingComps.length > 0 && !props.challenge,
  );

  useEffect(() => {
    if (promoType !== undefined) {
      return;
    }
    const showPromo =
      (canShowNewChallengePromo || canShowPlayAnotherGamePromo) &&
      (props.showPromo === true ||
        Math.random() < remoteConfig.showPromoPercentage / 100);

    setPromoType(
      showPromo
        ? !canShowNewChallengePromo ||
          Math.random() < remoteConfig.showMoreGamesPromoPercentage / 100
          ? PromoType.PLAY_ANOTHER_GAME
          : PromoType.NEW_CHALLENGE
        : PromoType.NONE,
    );
  }, [
    remoteConfig,
    canShowNewChallengePromo,
    canShowPlayAnotherGamePromo,
    compsPlayed,
    props.showPromo,
    promoType,
  ]);

  useEffect(() => {
    if (challenge && currentUser) {
      setChallengerId(challenge.users.find((u) => u !== currentUser.id));
    }
  }, [challenge, challengerId, currentUser]);

  useEffect(() => {
    if (compEntryStatus === EnterCompStatus.ERROR) {
      setShowError(true);
    } else if (compEntryStatus === EnterCompStatus.COMPLETE) {
      setShowError(false);
    }
  }, [compEntryStatus]);

  useEffect(() => {
    let sub: Subscription | undefined;
    if (challenge && challengerId && challenger === null) {
      sub = getUserByIdStream(gameUrl)(challengerId).subscribe({
        next: (u) => {
          setChallenger(u);
        },
      });
    }
    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [challenger, challengerId, challenge, gameUrl]);

  useEffect(() => {
    if (promoType === undefined) {
      return;
    }
    onScreenAnalytics(
      type,
      promoType ?? PromoType.NONE,
      isTemporary,
      isEntryWithMissedQuestions,
    );
  }, [
    type,
    promoType,
    isTemporary,
    isEntryWithMissedQuestions,
    onScreenAnalytics,
  ]);

  const compIsStillOpenForEntry = props.questions?.some(
    (q) =>
      q.status === QuestionStatus.OPEN ||
      q.status === QuestionStatus.OPEN_CHANGEABLE,
  );

  const title =
    compEntryStatus === EnterCompStatus.ERROR
      ? 'Whoops'
      : !compIsStillOpenForEntry
        ? 'Entries are closed'
        : isTemporary &&
            (type === EnterCompContentType.FIRST_N_GAMES ||
              type === EnterCompContentType.FIRST_N_VENDOR_GAMES)
          ? `You're in your first${
              props.vendorName ? ` ${props.vendorName}` : ''
            } game!`
          : isTemporary
            ? "You're in the game!"
            : type === EnterCompContentType.FIRST_N_VENDOR_GAMES && props.title
              ? props.title
              : type === EnterCompContentType.ANSWERED_OUTSTANDING_QUESTIONS
                ? 'You’re all up to date!'
                : 'Questions completed!';
  const description =
    compEntryStatus === EnterCompStatus.ERROR
      ? 'We have had a problem entering you in the competition.'
      : !compIsStillOpenForEntry
        ? 'Sorry, this competition is now in play. Entries are closed but look out for our next game and get in early!'
        : type === EnterCompContentType.FIRST_N_VENDOR_GAMES &&
            props.description
          ? props.description
          : '';

  const _getFooterComponent = () => {
    if (showError) {
      return (
        <PrimaryButton
          style={styles.button}
          label="Try Again"
          onPress={props.onRetryEntry}
        />
      );
    }
    return null;
  };
  const footerComponent = _getFooterComponent();

  const getCompListItem = (comp: ICompSummaryAndEntry) => (
    <Pressable
      style={styles.compListItem}
      onPress={() => props.onCompetitionSelected(comp)}>
      <CompDetailsListItem
        key={comp.summary.id}
        comp={comp}
        colorConfig={getColorConfiguration(comp.summary.primaryColor)}
        noColorBackground={true}
        promoMode={true}
      />
    </Pressable>
  );

  const _getBodyComponent = () =>
    !showError ? (
      challenge ? (
        <>
          <ChallengeVsAvatars
            style={styles.challengeAvatars}
            challengingUser={
              getChallengerId(challenge) === currentUser?.id
                ? currentUser
                : challenger
            }
            challengedUser={
              getChallengedId(challenge) === currentUser?.id
                ? currentUser
                : challenger
            }
          />
          <View style={styles.spacer} />
          <View>
            <Text style={styles.description}>
              Send
              <Text style={[styles.description, {fontFamily: FONT_COPY_BOLD}]}>
                {challenger?.name !== undefined ? ` ${challenger.name}` : ''}
              </Text>{' '}
              a message and let them know you are ready for action!
            </Text>
          </View>
          <View style={styles.spacer} />
          <SecondaryButton
            label="Send a message"
            onPress={() =>
              challenge && props.onChallengeSelected
                ? props.onChallengeSelected(challenge)
                : null
            }
            style={[styles.button, styles.challengeButton]}
          />
        </>
      ) : isTemporary ? (
        <View style={styles.imageBodyContainer}>
          <ImageBackground
            resizeMode="cover"
            style={[styles.image, styles.marginBottomZero]}
            source={{
              uri: `${props.cdnUrl}/vendors/_default-enter-comp-temporary.jpg`,
            }}
          />
          {isEntryWithMissedQuestions &&
            (props.type === EnterCompContentType.FIRST_N_VENDOR_GAMES ||
              props.type === EnterCompContentType.FIRST_N_GAMES) && (
              <FormattedDescription
                style={styles.description}
                description={
                  'But you missed some questions!\nMixnpik has some questions at the start of every game. Make sure to play early in the next game to maximise your chance of winning.'
                }
              />
            )}
          {isEntryWithMissedQuestions &&
            props.type !== EnterCompContentType.FIRST_N_VENDOR_GAMES &&
            props.type !== EnterCompContentType.FIRST_N_GAMES && (
              <FormattedDescription
                style={styles.description}
                description={
                  'You missed some questions!\nMake sure to play early in the next game to maximise your chance of winning.'
                }
              />
            )}
          {!isEntryWithMissedQuestions && (
            <FormattedDescription
              style={styles.description}
              description={
                "You're using a temporary account and you can't win a prize but you can still win respect and kudos! 🤩\nYou can upgrade your account at any time. Tap the profile at the bottom of the screen on the home page."
              }
            />
          )}
          {props.upcomingComps.length > 0 && (
            <View style={[styles.compListContainer, styles.marginTopSixteen]}>
              {props.upcomingComps
                .slice(0, 2)
                .map((comp) => getCompListItem(comp))}
            </View>
          )}
        </View>
      ) : (
        <>
          {type === EnterCompContentType.FIRST_N_VENDOR_GAMES &&
            props.mediaComponent}
          {(type === EnterCompContentType.DEFAULT ||
            type === EnterCompContentType.ANSWERED_OUTSTANDING_QUESTIONS) &&
            (promoType === PromoType.NONE ? (
              <View style={styles.defaultBodyContainer}>
                <View style={styles.vendorContainer}>
                  <Avatar
                    imageUrl={props.vendorImageUrl}
                    highlightWidth={0}
                    highlightGap={0}
                    size={96}
                    radius={8}
                  />
                </View>
                <View style={styles.avatarContainer}>
                  <Avatar
                    style={styles.avatar}
                    size={96}
                    highlightWidth={0}
                    imageUrl={currentUser?.media?.image?.url}
                  />
                </View>
              </View>
            ) : promoType === PromoType.PLAY_ANOTHER_GAME ? (
              <View style={styles.compListContainer}>
                {props.upcomingComps
                  .slice(0, 2)
                  .map((comp) => getCompListItem(comp))}
              </View>
            ) : promoType === PromoType.NEW_CHALLENGE ? (
              <View style={styles.imageBodyContainer}>
                <ImageWithFallback
                  resizeMode="cover"
                  style={styles.image}
                  uris={[
                    `${props.cdnUrl}/vendors/_default-enter-comp-challenge-promo.gif`,
                    `${props.cdnUrl}/vendors/_default-enter-comp-challenge-promo.png`,
                  ]}
                />
                <SecondaryButton
                  label="Find out more"
                  onPress={props.onNewChallengeSelected ?? undefined}
                  style={[
                    styles.button,
                    {backgroundColor: props.backgroundColor},
                  ]}
                />
              </View>
            ) : null)}
          {description && (
            <FormattedDescription
              style={styles.description}
              description={description}
            />
          )}
        </>
      )
    ) : (
      <FormattedDescription
        style={styles.description}
        description={description}
      />
    );
  const bodyComponent = _getBodyComponent();
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      {bodyComponent ? (
        bodyComponent
      ) : footerComponent ? (
        <View style={styles.spacer} />
      ) : null}
      {footerComponent}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    userSelect: 'none',
  },
  defaultBodyContainer: {
    flexDirection: 'row',
    marginTop: 32,
    marginBottom: 32,
  },
  avatarContainer: {
    width: 96 + 80,
  },
  avatar: {
    alignSelf: 'flex-end',
  },
  vendorContainer: {
    width: 96,
    aspectRatio: 2 / 2,
    borderRadius: 8,
    position: 'absolute',
    zIndex: 1,
    overflow: 'hidden',
  },
  title: {
    fontFamily: FONT_SUB_TITLE,
    fontSize: 18,
    color: COLOR_LIGHT,
    marginTop: 4,
    pointerEvents: 'none',
  },
  description: {
    minWidth: '100%',
    marginTop: 16,
    marginBottom: 16,
    fontFamily: FONT_COPY,
    fontSize: 14,
    color: COLOR_LIGHT,
    pointerEvents: 'none',
  },
  challengeAvatars: {
    marginTop: 32,
  },
  spacer: {
    marginVertical: 16,
    alignSelf: 'stretch',
    height: 0,
  },
  compListContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 32,
    width: '100%',
  },
  compListItem: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: COLOR_LIGHT,
    marginBottom: 16,
    width: '100%',
  },
  button: {
    width: '100%',
  },
  challengeButton: {
    backgroundColor: COLOR_PURPLE,
  },
  imageBodyContainer: {
    marginTop: 32,
    marginBottom: 32,
    alignItems: 'center',
  },
  image: {
    marginBottom: 32,
    marginHorizontal: 32,
    width: '100%',
    aspectRatio: 594 / 332,
    overflow: 'hidden',
    borderRadius: 16,
  },
  marginTopSixteen: {
    marginTop: 16,
  },
  marginBottomZero: {
    marginBottom: 0,
  },
});

import {Static, Type} from '@sinclair/typebox';
import {
  TAnnouncement,
  TChallengeGroup,
  TChatMessage,
  TComp,
  TCompCounts,
  TCompEntry,
  TCompQuestion,
  TCompStatusUpdated,
  TCompSummary,
  TDefaultGroup,
  TGroupCounts,
  TGroupPrivate,
  TInboxMessage,
  TLeaderboard,
  TLeaders,
  TMediaLibraryItemError,
  TMediaLibraryItemReady,
  TMediaLibraryItemPending,
  TMultiGameLeaders,
  TTempEntry,
  TUser,
  TUserFollowList,
  TUserInternalPrivateProfile,
  TUserPrivateProfile,
} from './FirestoreInterfaces';

const TClientDocument = Type.Object({
  id: Type.String(),
  path: Type.String(),
});
export type TClientDocument = Static<typeof TClientDocument>;

export const TFirebaseCompSummary = Type.Composite([
  TClientDocument,
  TCompSummary,
]);
export type TFirebaseCompSummary = Static<typeof TFirebaseCompSummary>;

export const TFirebaseComp = Type.Composite([TClientDocument, TComp]);
export type TFirebaseComp = Static<typeof TFirebaseComp>;

export const TFirebaseQuestion = Type.Composite([
  TClientDocument,
  TCompQuestion,
  Type.Object({index: Type.Number()}),
]);
export type TFirebaseQuestion = Static<typeof TFirebaseQuestion>;

export const TFirebaseCompCounts = Type.Composite([
  TClientDocument,
  TCompCounts,
]);
export type TFirebaseCompCounts = Static<typeof TFirebaseCompCounts>;

export const TFirebaseCompStatus = Type.Composite([
  TClientDocument,
  TCompStatusUpdated,
]);
export type TFirebaseCompStatus = Static<typeof TFirebaseCompStatus>;

export const TFirebaseCompEntry = Type.Composite([TClientDocument, TCompEntry]);
export type TFirebaseCompEntry = Static<typeof TFirebaseCompEntry>;

export const TFirebaseTempEntry = Type.Composite([TClientDocument, TTempEntry]);
export type TFirebaseTempEntry = Static<typeof TFirebaseTempEntry>;

export const TFirebaseLeaders = Type.Composite([TClientDocument, TLeaders]);
export type TFirebaseLeaders = Static<typeof TFirebaseLeaders>;

export const TFirebaseMultiGameLeaders = Type.Composite([
  TClientDocument,
  TMultiGameLeaders,
]);
export type TFirebaseMultiGameLeaders = Static<
  typeof TFirebaseMultiGameLeaders
>;

export const TFirebaseLeaderboard = Type.Composite([
  TClientDocument,
  TLeaderboard,
]);
export type TFirebaseLeaderboard = Static<typeof TFirebaseLeaderboard>;

export const TFirebaseInboxMessage = Type.Composite([
  TClientDocument,
  TInboxMessage,
]);
export type TFirebaseInboxMessage = Static<typeof TFirebaseInboxMessage>;

export const TFirebaseChatMessage = Type.Composite([
  TClientDocument,
  TChatMessage,
]);
export type TFirebaseChatMessage = Static<typeof TFirebaseChatMessage>;

export const TFirebaseUser = Type.Composite([TClientDocument, TUser]);
export type TFirebaseUser = Static<typeof TFirebaseUser>;

export const TFirebaseUserPrivateProfile = Type.Composite([
  TClientDocument,
  TUserPrivateProfile,
]);
export type TFirebaseUserPrivateProfile = Static<
  typeof TFirebaseUserPrivateProfile
>;

export const TFirebaseUserInternalPrivateProfile = Type.Composite([
  TClientDocument,
  TUserInternalPrivateProfile,
]);
export type TFirebaseUserInternalPrivateProfile = Static<
  typeof TFirebaseUserInternalPrivateProfile
>;

export const TFirebaseUserFollowList = Type.Composite([
  TClientDocument,
  TUserFollowList,
]);
export type TFirebaseUserFollowList = Static<typeof TFirebaseUserFollowList>;

export const TFirebaseDefaultGroup = Type.Composite([
  TClientDocument,
  TDefaultGroup,
]);
export type TFirebaseDefaultGroup = Static<typeof TFirebaseDefaultGroup>;
export const TFirebaseChallengeGroup = Type.Composite([
  TClientDocument,
  TChallengeGroup,
]);
export type TFirebaseChallengeGroup = Static<typeof TFirebaseChallengeGroup>;

// You cannot use Value.Cast on a Union type, the easiest way to enforce this is to not create the TypeBox definition
export type TFirebaseGroup = TFirebaseDefaultGroup | TFirebaseChallengeGroup;

export const TFirebaseGroupCounts = Type.Composite([
  TClientDocument,
  TGroupCounts,
]);
export type TFirebaseGroupCounts = Static<typeof TFirebaseGroupCounts>;

export const TFirebaseGroupPrivate = Type.Composite([
  TClientDocument,
  TGroupPrivate,
]);
export type TFirebaseGroupPrivate = Static<typeof TFirebaseGroupPrivate>;

export const TFirebaseAnnouncement = Type.Composite([
  TClientDocument,
  TAnnouncement,
]);
export type TFirebaseAnnouncement = Static<typeof TFirebaseAnnouncement>;

export const TFirebaseMediaLibraryItemPending = Type.Composite([
  TClientDocument,
  TMediaLibraryItemPending,
]);
export type TFirebaseMediaLibraryItemPending = Static<
  typeof TFirebaseMediaLibraryItemPending
>;
export const TFirebaseMediaLibraryItemError = Type.Composite([
  TClientDocument,
  TMediaLibraryItemError,
]);
export type TFirebaseMediaLibraryItemError = Static<
  typeof TFirebaseMediaLibraryItemError
>;
export const TFirebaseMediaLibraryItemReady = Type.Composite([
  TClientDocument,
  TMediaLibraryItemReady,
]);
export type TFirebaseMediaLibraryItemReady = Static<
  typeof TFirebaseMediaLibraryItemReady
>;

// You cannot use Value.Cast on a Union type, the easiest way to enforce this is to not create the TypeBox definition
export type TFirebaseMediaLibraryItem =
  | TFirebaseMediaLibraryItemPending
  | TFirebaseMediaLibraryItemError
  | TFirebaseMediaLibraryItemReady;

import {
  ClientStatus,
  IVendorDetails,
  QuestionResolutionStatus,
} from '../core/state/model/CompetitionModel';
import {ILocalAnswersForComp} from '../interfaces/client/ClientInterfaces';
import {
  TFirebaseComp,
  TFirebaseCompCounts,
  TFirebaseCompEntry,
  TFirebaseQuestion,
} from '../interfaces/firestore/FirestoreClientInterfaces';
import {
  CompQuestionType,
  CompStatus,
  TComp,
  TCompAnswer,
  TCompAnswerBreakdown,
  TCompResolvedQuestion,
  TCompSummary,
  TLeaderboard,
  TLeaderboardLevelDetails,
  TTimestamp,
} from '../interfaces/firestore/FirestoreInterfaces';

export const usersNumUnresolvedQuestions = (
  usersEntry: TFirebaseCompEntry | null,
  compData: TFirebaseCompCounts,
  comp: TFirebaseComp | null,
  status: CompStatus | null,
  questions: TFirebaseQuestion[] | null,
): number => {
  if (!comp) {
    return 0;
  }
  if (!questions) {
    return 0;
  }
  if (!usersEntry) {
    // User is not in the comp. Count all questions that are resolved and not multi answers.
    const resolved = compData.resolvedQuestions.filter((r) => {
      const question = findQuestionById(questions, r.id);
      return question && question.type !== CompQuestionType.MULTIANSWER;
    }).length;
    return compData.numQuestions - resolved;
  }
  // User is in the comp. Count all questions the user has not got a result for.
  return usersCompetitionResults(
    usersEntry,
    compData,
    status,
    questions,
  ).filter(
    (num) =>
      num === QuestionResolutionStatus.NO_ANSWER ||
      num === QuestionResolutionStatus.INCORRECT_OPEN,
  ).length;
};

export const usersCompetitionResults = (
  usersEntry: TFirebaseCompEntry | null,
  compData: TFirebaseCompCounts,
  status: CompStatus | null,
  questions: TFirebaseQuestion[] | null,
): QuestionResolutionStatus[] => {
  if (!questions) {
    return [];
  }
  if (!usersEntry) {
    return Array(compData.numQuestions).fill(
      QuestionResolutionStatus.NO_ANSWER,
    );
  }
  return Object.keys(usersEntry.answers).map((questionId, index) => {
    const answer = usersEntry.answers[questionId];
    const resolved = compData.resolvedQuestions.find(
      (r) => r.id === questionId,
    );
    const question = findQuestionById(questions, questionId);
    return resolved
      ? isAnswerCorrect(resolved.resolved, answer)
        ? QuestionResolutionStatus.CORRECT
        : question &&
            question.type === CompQuestionType.MULTIANSWER &&
            status === CompStatus.INPLAY
          ? QuestionResolutionStatus.INCORRECT_OPEN
          : QuestionResolutionStatus.INCORRECT
      : question &&
          (status === CompStatus.RESULTED || status === CompStatus.VERIFIED)
        ? QuestionResolutionStatus.INCORRECT
        : QuestionResolutionStatus.NO_ANSWER;
  });
};

const findQuestionById = (questions: TFirebaseQuestion[], id: string) => {
  return questions.find((q) => q.id === id);
};

export const getNumberCorrect = (
  resolvedQuestions: TCompResolvedQuestion[],
  answers: {[key: string]: number},
) => {
  let correct = 0;
  Object.keys(answers).map((questionId, index) => {
    const answer = answers[questionId];
    const resolved = resolvedQuestions.find((r) => r.id === questionId);
    if (resolved && isAnswerCorrect(resolved.resolved, answer)) {
      correct++;
    }
  });
  return correct;
};

export const isAnswerCorrect = (
  resolved: number | number[] | undefined,
  answer: number,
): boolean => {
  return Array.isArray(resolved)
    ? resolved.includes(answer)
    : resolved === answer;
};

export const getPercentagesBreakdownForComp = (answersBreakdown: {
  [key: string]: TCompAnswerBreakdown;
}) =>
  Object.keys(answersBreakdown).reduce(
    (acc, key) => {
      const answerCounts = answersBreakdown[key];
      const total = Object.keys(answerCounts).reduce(
        (acc, key) => acc + answerCounts[key],
        0,
      );
      const answerPercentageFloats = Object.keys(answerCounts).reduce(
        (counts, countsKey) => {
          counts[countsKey] = (answerCounts[countsKey] / total) * 100;
          return counts;
        },
        {} as Record<string, number>,
      );
      // Use accumulative values to ensure we end up with 100 https://stackoverflow.com/a/13483486
      const questionPercentages = Object.keys(answerPercentageFloats).reduce(
        (counts, countsKey) => {
          const percent = answerPercentageFloats[countsKey];
          const currentPercentage = counts.accumulatedPercentage;
          const currentPercentageRound = Math.round(
            counts.accumulatedPercentage,
          );
          const accumulatedPercentage = currentPercentage + percent;

          const calculatePercentage = Math.round(accumulatedPercentage);
          counts.percentages[countsKey] =
            calculatePercentage - currentPercentageRound;
          counts.accumulatedPercentage = accumulatedPercentage;
          return counts;
        },
        {percentages: {}, accumulatedPercentage: 0} as {
          percentages: Record<string, number>;
          accumulatedPercentage: number;
        },
      );
      acc[key] = questionPercentages.percentages;
      return acc;
    },
    {} as Record<string, Record<string, number>>,
  );

export const calulatePercentages = (
  answers: TCompAnswer[],
  answersBreakdown: TCompAnswerBreakdown,
  addUserSelected?: number,
) => {
  const total = Object.keys(answersBreakdown).reduce(
    (prev, next) => prev + answersBreakdown[next],
    1, // Start at 1 to account for the users answer that was just selected
  );

  const percentages = answers.map((answer, index) => {
    let timesSelected =
      answersBreakdown[`${index}`] !== undefined
        ? answersBreakdown[`${index}`]
        : 0;
    //Add users selection to answer count
    if (index === addUserSelected) {
      timesSelected = timesSelected + 1;
    }
    return Math.round((100 / total) * timesSelected);
  });

  return percentages;
};

export const getCompTitleHome = (compSummary: TCompSummary | TComp): string =>
  compSummary.shortName;

export const getCompSubTitleHome = (
  compSummary: TCompSummary | TComp,
): string => compSummary.name;
// compSummary.media.caption && compSummary.media.caption !== ''
//   ? compSummary.media.caption
//   : compSummary.name;

export const getCompTitleGameTiles = (
  compSummary: TCompSummary,
  isMini = false,
): string =>
  isMini
    ? compSummary.roundName ?? compSummary.shortName
    : compSummary.shortName;

export const getLeaderboardLevelDescription = (
  status: ClientStatus,
  details?: TLeaderboardLevelDetails,
): string | undefined => {
  if (details === undefined) {
    return undefined;
  }
  if (typeof details === 'string') {
    return details;
  } else {
    return details[getLeaderboardState(status)];
  }
};

const getLeaderboardState = (status: ClientStatus) => {
  switch (status) {
    case ClientStatus.OPEN:
      return 'open';
    case ClientStatus.IN_PLAY:
      return 'inPlay';
    default:
      return 'resulted';
  }
};

export const getAnswersBreakdownForQuestion = (
  questionId: string,
  counts: TFirebaseCompCounts,
) => {
  return counts?.answersBreakdown[questionId] ?? {};
};

export const getLikesForQuestion = (
  questionId: string,
  counts: TFirebaseCompCounts,
) => {
  return counts?.likesBreakdown[questionId] ?? 0;
};

export const getUserSelectedAnswer = (
  questionId: string,
  answers: ILocalAnswersForComp,
) => {
  return answers?.answers[questionId];
};

export const getUserHasLiked = (
  questionId: string,
  answers: ILocalAnswersForComp,
  usersEntryStatus: TFirebaseCompEntry | null,
) => {
  return (
    answers?.likes[questionId] ?? usersEntryStatus?.likes[questionId] ?? false
  );
};

export const getCompetitionClientStatus = (
  starts: TTimestamp,
  serverStatus: CompStatus | null,
) => {
  const msRemainingToStart = starts.toMillis() - new Date().valueOf();

  switch (serverStatus) {
    case null:
    case CompStatus.DRAFT:
    case CompStatus.OPEN:
      if (msRemainingToStart > 0) {
        return ClientStatus.OPEN;
      } else {
        return ClientStatus.PRE_START;
      }
    case CompStatus.INPLAY:
      return ClientStatus.IN_PLAY;
    case CompStatus.SUSPENDED:
      return ClientStatus.SUSPENDED;
    case CompStatus.RESULTED:
      return ClientStatus.RESULTED;
    default:
      return ClientStatus.VERIFIED;
  }
};

export const getCompetitionIsLive = (serverStatus: CompStatus | null) => {
  return (
    serverStatus === CompStatus.INPLAY ||
    serverStatus === CompStatus.OPEN ||
    serverStatus === CompStatus.RESULTED
  );
};

export const createCompetitionVendor = (
  comp: TCompSummary | TComp,
): IVendorDetails => ({
  vendor: comp.vendor,
  media: comp.logo,
  primaryColor: comp.primaryColor,
  name: comp.logo.caption ?? comp.shortName,
  strapline: comp.logo.description ?? comp.strapline ?? '',
  isMiniLeague: 'status' in comp ? isMiniLeague(comp) : false,
  tags: comp.tags ?? [],
});

const isMiniLeague = (
  compSummaryOrLeaderboard: TCompSummary | TLeaderboard,
): boolean => {
  if ('leaderboards' in compSummaryOrLeaderboard) {
    return compSummaryOrLeaderboard.leaderboards.some(
      (l) => l.isMiniLeague === true,
    );
  } else {
    return compSummaryOrLeaderboard.isMiniLeague === true;
  }
};

import {
  TFirebaseCompSummary,
  TFirebaseCompEntry,
} from '../../../interfaces/firestore/FirestoreClientInterfaces';
import {TCompLogo} from '../../../interfaces/firestore/FirestoreInterfaces';

export enum ClientStatus {
  RESULTED = 0,
  IN_PLAY = 1,
  PRE_START = 2,
  OPEN = 3,
  VERIFIED = 4,
  SUSPENDED = 5,
}

export enum QuestionResolutionStatus {
  INCORRECT = -1,
  NO_ANSWER = 0,
  CORRECT = 1,
  INCORRECT_OPEN = 2,
}

export interface ICompSummaryAndEntry {
  summary: TFirebaseCompSummary;
  vendor: IVendorDetails;
  entry: TFirebaseCompEntry | null;
  outstandingQuestions: number;
}

export interface IVendorDetails {
  vendor: string;
  media: TCompLogo;
  primaryColor: string;
  name: string;
  strapline: string;
  isMiniLeague: boolean;
  tags: string[];
}

import React, {ReactElement, useMemo} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import {
  COLOR_BACKGROUND,
  COLOR_GREY_4,
  COLOR_GREY_8,
  COLOR_LIGHT,
  FONT_COPY_MEDIUM,
  FONT_TITLE,
} from '../../Styles/DesignSystem-chancer';

interface IProps {
  safeAreaTopPadding?: number;
  color: string;
  title: string;
  description: string;
  media: ReactElement;
  onClose: () => void;
}

export const AnnouncementContent: React.FC<IProps> = (props) => {
  const {safeAreaTopPadding = 0} = props;
  const styles = useMemo(
    () => getStyles(safeAreaTopPadding),
    [safeAreaTopPadding],
  );
  return (
    <Pressable style={styles.pressableContainer} onPress={props.onClose}>
      <LinearGradient
        colors={[props.color, COLOR_BACKGROUND]}
        style={styles.container}>
        <View style={styles.mediaContainer}>{props.media}</View>
        <Text style={styles.title}>{props.title}</Text>
        <Text style={styles.description}>{props.description}</Text>
      </LinearGradient>
    </Pressable>
  );
};

const getStyles = (safeAreaTopPadding: number) =>
  StyleSheet.create({
    pressableContainer: {
      flex: 1,
    },
    container: {
      flex: 1,
      height: '100%',
      color: COLOR_GREY_4,
      paddingTop: 90 + safeAreaTopPadding,
      paddingHorizontal: 32,
      paddingBottom: 32,
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    mediaContainer: {
      flexGrow: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      maxWidth: '100%',
      aspectRatio: 1,
      overflow: 'hidden',
    },
    title: {
      width: '100%',
      fontFamily: FONT_TITLE,
      fontSize: 21,
      color: COLOR_LIGHT,
      marginTop: 32,
    },
    description: {
      width: '100%',
      marginTop: 16,
      fontSize: 16,
      fontFamily: FONT_COPY_MEDIUM,
      color: COLOR_GREY_8,
    },
  });
